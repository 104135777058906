import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Helmet from "react-helmet";
import Layout from 'components/Layout';
import BlogPost from 'components/pages/BlogPost';
import { Image } from 'components/pages/BlogPost/styled';
import { ImgGatsbyBackground } from 'components/wrappers';
import styled, { css } from 'styled-components';
import { blogPosts } from 'common/blogPostsFeatured';
import BlogBaseCard from 'components/blog/BaseCard';
import BlogSidebar from 'components/blog/BlogSidebar';
import {PostsWrapper} from 'components/pages/Blog/styled';
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from 'react-share';
import FacebookDefaultIcon from 'images/social-share-icons/facebook-white.png';
import TwitterDefaultIcon from 'images/social-share-icons/twitter-white.png';
import LinkedinDefaultIcon from 'images/social-share-icons/linkedin-white.png';
import BlogOptin from 'components/blog/BlogOptin';
import {Clear, BlogRow, ShareButton, ReadNow, DateText, TitleText, UnorderedList, OrderedList, FirstTitle, BottomShare, SectionFull, OptinSection, ColumnHalf, ColumnHalfLast} from 'components/blog/SingleBlogStyle';

import YoutubeShoppingImg from 'images/blog-images/youtube-shopping.png';
import YoutubeShoppingPlatformFeatured from 'images/featured-image/google-youtube-shopping-platform-featured.png';
import YoutubeShoppingPlatform from 'images/blog-images/google-youtube-shopping-platform.png';

interface OwnProps {
  location: {
    href: string;
  };
}

const BlogArticle = ({ location }: OwnProps) => {
  const data = useStaticQuery(graphql`
    query YoutubeShoppingPlatform {
      file(relativePath: { eq: "google-youtube-shopping-platform.png" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1400) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const BackgroundImage = () => <ImgGatsbyBackground fluid={data.file.childImageSharp.fluid} alt="google wants to turn youtube into a shopping platform"/>;
  const { href } = location;
  const intl = useIntl()
  return (
    <Layout>
      <Helmet 
        // title="Google Wants to Turn YouTube into a Shopping Platform | Shipkoo"
        title={intl.formatMessage({ id: "blog-post-meta-title-google-wants" })}
         meta={
          [
            {
              name: `description`,
              content: "",
            },
            {
              property: `og:title`,
              // content: "Google Wants to Turn YouTube into a Shopping Platform",
              content: intl.formatMessage({ id: "blog-post-meta-title-google-wants" }),
            },
            {
              property: `og:description`,
              content: "",
            },
            {
              property: `og:image`,
              content: YoutubeShoppingPlatformFeatured,
            },
            {
              property: `og:type`,
              content: `website`,
            },
            {
              name: `twitter:card`,
              content: `summary`,
            },
          ] as JSX.IntrinsicElements['meta'][]
        }
      />
      <BlogPost
        location={location}
        id="google-wants-to-turn-youtube-into-a-shopping-platform"
        title="Google Wants to Turn YouTube into a Shopping Platform"
        date="2020-10-14"
        author="Joe Zhang"
        Background={BackgroundImage}
      >
        <BlogRow>
          <BlogSidebar/>
          <div className="column-half last">

            <p>
              According to reports by <a href="https://www.bloomberg.com/news/articles/2020-10-09/google-tries-to-turn-youtube-into-a-major-shopping-destination" target="_blank" rel="noreferrer">Bloombergs</a>, Google wants to make its video site YouTube a major shopping destination, thereby becoming a competitor to Amazon and Alibaba. As the world's largest video site, YouTube recently began to require creators to use YouTube software to tag and track products in their video works. These data will then be linked to Google’s analytics and shopping tools.
            </p>

            <Image src={YoutubeShoppingPlatform} className="" alt="google wants to turn youtube into a shopping platform"/>

            <h5>
              <strong>
                Why Google wants to Enter E-commerce Market
              </strong>
            </h5>

            <p>
              Google has tried e-commerce business many times and  is always hungry for new, non-advertising revenue streams, but the result is unsatisfied. In most cases, Google is more inclined to sell advertising business, taking customers to other digital stores, rather than selling products on their own. The severe impact of this year’s epidemic disrupted Google’s marketing strategy, especially in the areas of offline physical store retail and travel, which are the bulk of Google’s advertising revenue. 
            </p>

            <p>
              Amazon’s sales soared in the second quarter of this year, while Google experienced its first revenue decline in its history in the second quarter. In addition, as Facebook announced that it will be more involved in e-commerce, Google obviously does not want to miss the big cake of e-commerce. At the same time, more people are more accustomed to staying at home to buy goods online, and e-commerce will further flourish. It further confirmed Google's determination to set foot in the field of online e-commerce.
            </p>

            <p>
              Andy Ellwood, president of e-commerce startup Basket, said: "YouTube is one of the least utilized assets. If they decide to invest, it will be a huge superior for them." Google executives have always said that YouTube will be the core of Google's e-commerce strategy in the future
            </p>

            <p>
              As the world's largest video site and the second largest search engine (the first is the Google search engine), YouTube recently began to require creators to use YouTube software while tracking and marking products in their video works. These data will then be linked to Google’s analysis and shopping tools. Users can place an order directly on the website for every handicraft, gift and other goods that they see on YouTube. Of course, not on the Amazon website, but directly on YouTube.
            </p>

            <Image src={YoutubeShoppingImg} className="" alt="youtube shopping"/>

            <h5>
              <strong>
                Cooperation with Shopify
              </strong>
            </h5>

            <p>
              YouTube is also trying to further integrate with the e-commerce company Shopify to sell products through YouTube. The purpose of Google’s move is to convert a large number of videos on YouTube into a huge catalog of goods that viewers can browse, click and purchase directly. These measures are likely to transform YouTube from an advertising giant to an e-commerce giant, and thus become a new competitor to leading e-commerce companies such as Amazon and Alibaba.
            </p>

            <h5>
              <strong>
                Revenue Method
              </strong>
            </h5>

            <p>
              It is not yet certain how YouTube will get revenue from these sales, but YouTube has begun to provide creators with subscriptions and withdraw a 30% commission from these payments.
            </p>

            <h5>
              <strong>
                Payment Method
              </strong>
            </h5>

            <p>
              Google also adjusted the e-commerce and payment strategies. In July of this year, Google announced a plan to cancel the fees charged by the Google Shopping service to retailers in order to attract merchants on rival Amazon. Google also announced that it will integrate with Shopify and PayPal to help merchants manage inventory and sell products directly on Google.
            </p>

            <h5>
              <strong>
                Competitiveness of YouTube
              </strong>
            </h5>

            <UnorderedList>
              <li>
                With Google's huge traffic entrance and huge user data, it can push products more accurately.
              </li>
              <li>
                YouTube itself is a video website, and video promotion of products is far more than other e-commerce platforms to allow users to intuitively experience products
              </li>
              <li>
                Strong alliances with other leading companies in various fields, such as PayPal in the overseas online payment industry; independent station builder Shopify, etc.
              </li>
            </UnorderedList>

            <BottomShare>
              <div className="column-half">
                <p>Share the article</p>
              </div>
              <div className="column-half last">
                <FacebookShareButton url={href}>
                  <ShareButton><img src={FacebookDefaultIcon} alt="Facebook Icon" /> <span>Share</span></ShareButton>
                </FacebookShareButton>
                <TwitterShareButton url={href}>
                  <ShareButton><img src={TwitterDefaultIcon} alt="Twitter Icon" /> <span>Tweet</span></ShareButton>
                </TwitterShareButton>
                <LinkedinShareButton url={href}>
                  <ShareButton><img src={LinkedinDefaultIcon} alt="Linked Icon" /> <span>Share</span></ShareButton>
                </LinkedinShareButton>
              </div>
              <Clear></Clear>
            </BottomShare> 
          </div>  
          <Clear></Clear>
       </BlogRow> 
      </BlogPost>
      <SectionFull>
        <BlogRow>
          <h2>More From Shipkoo</h2>
          <PostsWrapper>
            {blogPosts.map(post => (
              <BlogBaseCard key={`${post.title}-${post.id}`} post={post} />
            ))}
          </PostsWrapper>
        </BlogRow>
      </SectionFull>
      <BlogOptin />
    </Layout>
  );
};

export default BlogArticle;